import { client } from '@/lib/sanity.client'
import { PreviewSuspense } from 'next-sanity/preview'
import { lazy } from 'react'

import Page from '@/components/page/Page'
import { I18NCONFIG } from '@/constants/Config'
import { FOOTER_QUERY, HREFLANGS_QUERY, MENU_QUERY, PAGE_QUERY, SLUG_QUERY } from '@/constants/QuerysGroq'
import { errorISR } from '@/constants/sentryErrors'
import { captureError } from '@/utils/SentryUtilities'
import { cleanPageID } from '@/utils/cleanPageID'

const PreviewPage = lazy(() => import('@/components/preview-page/PreviewPage'))

export async function getStaticPaths() {
  const pages = await client.fetch(SLUG_QUERY)
  const paths = pages?.map((page) => {
    const [locale, ...relativePathParts] = page.slug.split('/')

    return {
      params: { slug: relativePathParts },
      locale
    }
  })

  return {
    paths,
    fallback: 'blocking'
  }
}

export async function getStaticProps({ locale, params, preview = false }) {
  let isRoot = false
  if (!params || !params.slug || params.slug.length === 0) isRoot = true

  const slug = isRoot ? locale : [locale, ...params?.slug]?.join('/')

  try {
    if (preview) {
      return { props: { preview, slug, locale } }
    }

    const data = await client.fetch(PAGE_QUERY, { slug })
    const hreflangs = await client.fetch(HREFLANGS_QUERY, { idBase: cleanPageID(data?._id) })

    const isBaseLocale = locale === I18NCONFIG.base
    const localeForFetch = isBaseLocale ? { locale: null } : { locale }

    const [menuData, footerData] = await Promise.all([
      client.fetch(MENU_QUERY, localeForFetch),
      client.fetch(FOOTER_QUERY, localeForFetch)
    ])

    if (!data && locale !== 'default') {
      captureError(errorISR, `No data found Error in ${slug}`, { slug })

      return {
        notFound: true,
        revalidate: 5
      }
    }

    console.log(`[Next.js] Running getStaticProps for ${slug}`)
    return {
      props: { preview, data, hreflangs, menuData, footerData, slug, locale },
      revalidate: 600
    }
  } catch (e) {
    console.log(`Error in ${slug} - ${e}`)
    captureError(errorISR, `Error in ${slug} - ${e}`, { slug })
    return {
      notFound: true,
      revalidate: 5
    }
  }
}

export default function PageWrapper({ preview, token, data, hreflangs, menuData, footerData, slug, locale }) {
  if (preview) {
    return (
      <PreviewSuspense fallback='Loading...'>
        <PreviewPage token={token} slug={slug} locale={locale} />
      </PreviewSuspense>
    )
  }

  return <Page data={data} hreflangs={hreflangs} menuData={menuData} footerData={footerData} />
}
