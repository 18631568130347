import React from 'react'
import { parse } from 'node-html-parser'

export function parseAndCreateElements(htmlString) {
  const html = parse(htmlString)

  const createReactElement = (node) => {
    if (node.nodeType === 3) {
      return node.text.trim() ? node.text : null
    }

    const props = {
      ...node.attributes,
      key: node.tagName + Math.random()
    }

    if (node.tagName === 'SCRIPT' && node.attributes.type === 'application/ld+json') {
      props.dangerouslySetInnerHTML = { __html: node.innerHTML }
      return React.createElement(node.tagName, props)
    }

    const children = node.childNodes.map(createReactElement).filter(Boolean)
    return React.createElement(node.tagName, props, ...children)
  }

  return html.childNodes.map(createReactElement).filter(Boolean)
}
